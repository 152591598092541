.container-middle {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.desc-tech {
  padding-left: 30px;
}

.format-list {
  padding-left: 50px;
}
