@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

body {
  margin: 0;
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #1a1a1a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 70px 20px 20px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Dancing Script', cursive;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 96px;
  color: #D35563;
}

h3 {
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 101px;
  color: #5A819E;
  margin: 0px;
}

h4 {
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 52px;
}

h5 {
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 57px;
  color: #5A819E;
  margin: 0px;
  padding-left: 30px;
}

p {
  padding-bottom: 15px;
}

a {
  color: #5A819E;
}

a:hover {
  color: #D35563;
}

ul {
  list-style-type: none;
  margin: 0;
}
