.container-format {
  display: flex;
  flex-wrap: wrap;
  margin: 0 2% 20px 2%;
}

.container-format>*:nth-child(1) {
  min-width: 50%;
}

.container-format>*:nth-child(2) {
  flex: 1;
}

.item-desc {
  max-width: 650px;
  padding-right: 10px;
  padding-left: 30px;
}

.format-name {
  color: #D35563;
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0px;
  padding-left: 30px;
}

.item-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
