.container-project {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.format-desc {
  max-width: 600px;
}

.format-img {
  width: 250px;
}

.format-title {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
}
