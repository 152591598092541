.container-progress {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-family: "Noto Serif JP";
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 57px;
  color: #5A819E;
}