.container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
}

.container-left {
  display: flex;
  flex-direction: column;
  flex: 0 1 540px;
  padding-right: 30px;
}

.item-left1 {
  font-family: "Noto Serif JP";
  font-size: 30px;
  color: #5A819E;
}

.item-left2 {
  padding: 50px 0 50px 0;
}

.item-left3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 420px;
}

.img-Bruna {
  width: 350px;
  height: 350px;
}

.text-bio {
  font-family: "Noto Sans JP";
  font-size: 20px;
  line-height: 35px;
  color: black;
}

.name {
  font-family: "Dancing Script";
  font-size: 40px;
  line-height: 96px;
  color: #D35563;
}

.bt-icon {
  padding-left: 10px;
}

@media (max-width: 576px) {
  .img-Bruna {
    width: 250px;
    height: 250px;
  }
  .container-left{
    align-items: center;
    text-align: center;
  }
}
