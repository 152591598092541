.navbar {
  display: flex;
  flex-wrap: wrap;
  background: hsl(206, 27%, 49%);
  padding: 0 20px;
  min-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.left-side {
  display: flex;
  align-items: center;
}

.img-logo {
  height: 45px;
  width: 45px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

.right-side li a {
  text-decoration: none;
  color: white;
  padding: 1em 1em 0.8em;
  font-weight: 400;
  font-size: 18px;
}

.right-side li a:hover {
  background: hsl(206, 27%, 70%);
}

.menu-config {
  display: none;
}

@media (max-width: 576px) {
  .menu-config {
    display: flex;
  }

  .right-side {
    display: none;
  }
}
