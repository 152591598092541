.contact-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

.container-top {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.contact-list {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 0;
}

.contact-list li {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.contact-flex p {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  color: black;
}

.icon {
  font-size: 45px;
  color: #D35563;
  padding-right: 10px;
  flex-shrink: 0;
}

.format-email {
  color: #5A819E;
  align-self: center;
}

.format-contact-list {
  display: flex;
  align-items: center
}
